<template>
  <v-card
    v-if="
      relatorio.PropriedadeRuralEmDia ||
      relatorio.EstruturaLimpa ||
      relatorio.DestinacaoDejetos ||
      relatorio.DesmatamentoSemAutorizacao ||
      relatorio.DescarteLubrificantes ||
      relatorio.DestinacaoEsgotoDomestico ||
      relatorio.PreparoDaTerraEvitandoErosao ||
      relatorio.AreaPlantioRespeitaLimites ||
      relatorio.AtividadesPredatoriasNaPropriedade
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Boas práticas ambientais</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.PropriedadeRuralEmDia == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'A propriedade rural está em dia com o CAR?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.PropriedadeRuralEmDia != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A propriedade rural está em dia com o CAR?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.PropriedadeRuralEmDiaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Providenciar o CAR (cadastro ambiental rural).' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.EstruturaLimpa == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A estrutura de curral de manejo e ordenha é limpa diariamente não permitindo o acúmulo de esterco?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.EstruturaLimpa != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A estrutura de curral de manejo e ordenha é limpa diariamente não permitindo o acúmulo de esterco?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.EstruturaLimpaRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Limpar diariamente a estrutura de ordenha.' }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.EstruturaLimpaRecomendacao2" cols="12">
              <span class="value-title">
                {{ 'Limpar diariamente a estrutura de curral.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.DestinacaoDejetos == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os dejetos são destinados frescos para rios, córregos, lagos ou outras fontes de água?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.DestinacaoDejetos != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os dejetos são destinados frescos para rios, córregos, lagos ou outras fontes de água?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.DestinacaoDejetosRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Providenciar o destino correto para os dejetos. (ex: caixa de dejetos)'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.DesmatamentoSemAutorizacao == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A propriedade utiliza  de  desmatamento   de   mata   nativa, sem autorização para qualquer fim?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.DesmatamentoSemAutorizacao != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A propriedade utiliza  de  desmatamento   de   mata   nativa, sem autorização para qualquer fim?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.DesmatamentoSemAutorizacaoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Não realizar desmatamento de mata nativa sem autorização.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.DescarteLubrificantes == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Lubrificantes são descartados da forma adequada?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.DescarteLubrificantes != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Lubrificantes são descartados da forma adequada?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.DescarteLubrificantesRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Descartar lubrificantes na forma adequada (armazenar em tambores e levar em local próprio para descarte).'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.DestinacaoEsgotoDomestico == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Casas nas dependências da propriedade tem destino adequado para o esgoto doméstico?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.DestinacaoEsgotoDomestico != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Casas nas dependências da propriedade tem destino adequado para o esgoto doméstico?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.DestinacaoEsgotoDomesticoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Providenciar destino adequado para o esgoto doméstico (ex: fossas sépticas).'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.PreparoDaTerraEvitandoErosao == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Preparo da terra para plantio em áreas íngremes respeitam curvas de nível ou preservam estrutura evitando erosão?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.PreparoDaTerraEvitandoErosao != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Preparo da terra para plantio em áreas íngremes respeitam curvas de nível ou preservam estrutura evitando erosão?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.PreparoDaTerraEvitandoErosaoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Respeitar áreas íngremes e curvas de nível no preparo da terra para plantio.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AreaPlantioRespeitaLimites == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Áreas de plantio ou pastagem respeitam o limite mínimo de mata proximo dos rios ou mananciais?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AreaPlantioRespeitaLimites != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Áreas de plantio ou pastagem respeitam o limite mínimo de mata proximo dos rios ou mananciais?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AreaPlantioRespeitaLimitesRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Respeitar o limite mínimo de mata proximo dos rios ou mananciais.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AtividadesPredatoriasNaPropriedade == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'São realizadas atividades de caça e pesca, mesmo que por terceiros, nas dependências da propriedade?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AtividadesPredatoriasNaPropriedade != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'São realizadas atividades de caça e pesca, mesmo que por terceiros, nas dependências da propriedade?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AtividadesPredatoriasNaPropriedadeRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Não permitir atividades de caça e pesca, mesmo que por terceiros, nas dependências da propriedade.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
