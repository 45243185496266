<template>
  <v-card
    v-if="
      relatorio.PossuiProtocolosDeTratamentos ||
      relatorio.OrdenhaRealizadaDeFormaCorreta ||
      relatorio.AreaDePastejoEmBoasCondicoes ||
      relatorio.BebedourosAdequados ||
      relatorio.ControleDeCarrapatosEParasitas ||
      relatorio.PrevencaoEControleDeMastites ||
      relatorio.AnimaisConduzidosCorretamente ||
      relatorio.PastoEmBoasCondicoes ||
      relatorio.AlimentacaoSuficiente ||
      relatorio.CamasSuficientes ||
      relatorio.PermitirOBomDesenvolvimentoDosAnimais ||
      relatorio.OrdenhaMecanicaRegulada
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Boas práticas em conforto animal</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.PossuiProtocolosDeTratamentos == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A fazenda possui protocolos de tratamentos para as doenças mais frequentes do rebanho?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.PossuiProtocolosDeTratamentos != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A fazenda possui protocolos de tratamentos para as doenças mais frequentes do rebanho?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.PossuiProtocolosDeTratamentosRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Providenciar protocolos de tratamentos para as doenças mais frequentes do rebanho.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.OrdenhaRealizadaDeFormaCorreta == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A ordenha é realizada de forma calma, sem o uso de violência ou injúrias aos animais?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.OrdenhaRealizadaDeFormaCorreta != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A ordenha é realizada de forma calma, sem o uso de violência ou injúrias aos animais?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.OrdenhaRealizadaDeFormaCorretaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar a ordenha de forma calma, sem o uso de violência ou injúrias aos animais.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AreaDePastejoEmBoasCondicoes == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A área de pastejo dispõe de piquetes com sombra, livres do acúmulo de barro e esterco?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AreaDePastejoEmBoasCondicoes != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A área de pastejo dispõe de piquetes com sombra, livres do acúmulo de barro e esterco?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AreaDePastejoEmBoasCondicoesRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Providenciar sombrites na área de pastejo e descanso dos animais.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.AreaDePastejoEmBoasCondicoesRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{ 'Limpar as áreas de pastejo de descanso dos animais.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.BebedourosAdequados == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Bebedouros em quantidade suficiente e de fácil acesso?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.BebedourosAdequados != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Bebedouros em quantidade suficiente e de fácil acesso?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.BebedourosAdequadosRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Ajustar o número de animais para que todos tenham acesso ao bebedouro.'
                }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.BebedourosAdequadosRecomendacao2" cols="12">
              <span class="value-title">
                {{
                  'Ajustar o número de bebedouro para que todos os animais tenham acesso.'
                }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.BebedourosAdequadosRecomendacao3" cols="12">
              <span class="value-title">
                {{ 'Melhorar o acesso dos animais aos bebedouros.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ControleDeCarrapatosEParasitas == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os animais são submetidos a controle racional de carrapatos e parasitas diversos?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.ControleDeCarrapatosEParasitas != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os animais são submetidos a controle racional de carrapatos e parasitas diversos?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.ControleDeCarrapatosEParasitasRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Providenciar e executar controle estratégico para carrapatos e parasitas diversos.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.PrevencaoEControleDeMastites == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Existe prevenção e controle de mastites clínicas?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.PrevencaoEControleDeMastites != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Existe prevenção e controle de mastites clínicas?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.PrevencaoEControleDeMastitesRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AnimaisConduzidosCorretamente == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os animais são conduzidos para a ordenha de forma calma e ordenada?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AnimaisConduzidosCorretamente != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os animais são conduzidos para a ordenha de forma calma e ordenada?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AnimaisConduzidosCorretamenteRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Conduzir os animais para a ordenha de forma calma e ordenada.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.PastoEmBoasCondicoes == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Pasto de vacas secas, maternidade e pós-parto estão livres de acúmulo de barro?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.PastoEmBoasCondicoes != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Pasto de vacas secas, maternidade e pós-parto estão livres de acúmulo de barro?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.PastoEmBoasCondicoesRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Limpar as áreas de pastejo de descanso dos animais.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AlimentacaoSuficiente == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os animais dispõem de alimentação volumosa suficiente a sua mantença o ano todo?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AlimentacaoSuficiente != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os animais dispõem de alimentação volumosa suficiente a sua mantença o ano todo?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AlimentacaoSuficienteRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar planejamento de volumoso para que os animais tenham alimento o ano todo.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.CamasSuficientes == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Dispõe do número de camas dimensionadas e que propiciem conforto?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.CamasSuficientes != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Dispõe do número de camas dimensionadas e que propiciem conforto?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.CamasSuficientesRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Ajustar a taxa de lotação dos lotes.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.PermitirOBomDesenvolvimentoDosAnimais == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'O bezerreiro permite que animais se desenvolvam bem, livre de doenças e parasitas?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.PermitirOBomDesenvolvimentoDosAnimais != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O bezerreiro permite que animais se desenvolvam bem, livre de doenças e parasitas?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="
                relatorio.PermitirOBomDesenvolvimentoDosAnimaisRecomendacao1
              "
              cols="12"
            >
              <span class="value-title">
                {{ 'Providenciar sombreamento aos animais.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="
                relatorio.PermitirOBomDesenvolvimentoDosAnimaisRecomendacao2
              "
              cols="12"
            >
              <span class="value-title">
                {{ 'Higienizar diariamente o bezerreiro.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="
                relatorio.PermitirOBomDesenvolvimentoDosAnimaisRecomendacao3
              "
              cols="12"
            >
              <span class="value-title">
                {{
                  'Higienizar diariamente os utensílios utilizados para a alimentação dos animais.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.OrdenhaMecanicaRegulada == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A ordenha mecânica está regulada, evitando desconforto e dor no ato da ordenha?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.OrdenhaMecanicaRegulada != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A ordenha mecânica está regulada, evitando desconforto e dor no ato da ordenha?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.OrdenhaMecanicaReguladaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Contatar a assistência técnica especializada para regular o equipamento de ordenha.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
