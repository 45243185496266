<template>
  <v-card
    v-if="
      relatorio.OrdenhaSeparadaVacasDoentes ||
      relatorio.MediaGeometricaDentroDaLegislacao ||
      relatorio.OutrosAnimaisTemAcessoASalas ||
      relatorio.RealizacaoDaPreparaçãoDoAnimal ||
      relatorio.UtilizadaAguaQuenteNaLimpeza ||
      relatorio.ExamesDeBruceloseTuberculose ||
      relatorio.LoteVacaSecaSeparado ||
      relatorio.ArmazenamentoAgrotoxicosEFertilizantes ||
      relatorio.TermMetroDigitalParaMonitoramento ||
      relatorio.EquipamentosDeOrdenhaHigienizados ||
      relatorio.DetergentesRegistradosComValidadeEIdentificaoCorreta ||
      relatorio.AlimentosComNotaFiscal
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left"
      >Boas práticas em qualidade do leite</v-card-title
    >
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.OrdenhaSeparadaVacasDoentes == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'São ordenhadas separadamente e descartado o leite de vacas doentes, em tratamento e com colostro?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.OrdenhaSeparadaVacasDoentes != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'São ordenhadas separadamente e descartado o leite de vacas doentes, em tratamento e com colostro?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.OrdenhaSeparadaVacasDoentesRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Separar e descartar o leite de vacas doentes, em tratamento e com colostro.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.MediaGeometricaDentroDaLegislacao == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A média geométrica dos últimos três meses de CPP e CCS estão dentro da legislação vigente?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.MediaGeometricaDentroDaLegislacao != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A média geométrica dos últimos três meses de CPP e CCS estão dentro da legislação vigente?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Providenciar termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao3"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Higienizar diariamente os equipamentos de ordenha, tanque e utensílios.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao4"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Contatar a assistência técnica especializada para regular o equipamento de ordenha.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao5"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Utilizar água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.OutrosAnimaisTemAcessoASalas == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'As galinhas e suínos têm acesso à área de produção, sala do tanque e sala de ordenha?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.OutrosAnimaisTemAcessoASalasRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Proteger a área de produção, sala do tanque e sala de ordenha para que as galinhas e suínos tenham acesso.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <v-col
          v-else-if="relatorio.OutrosAnimaisTemAcessoASalas != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'As galinhas e suínos têm acesso à área de produção, sala do tanque e sala de ordenha?'
            }}
          </span>
        </v-col>

        <v-col
          v-if="relatorio.RealizacaoDaPreparaçãoDoAnimal == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'É realizado a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.RealizacaoDaPreparaçãoDoAnimal != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'É realizado a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.RealizacaoDaPreparaçãoDoAnimalRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.UtilizadaAguaQuenteNaLimpeza == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'É utilizada água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.UtilizadaAguaQuenteNaLimpeza != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'É utilizada água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.UtilizadaAguaQuenteNaLimpezaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Utilizar água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ExamesDeBruceloseTuberculose == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Faz exames de brucelose, tuberculose e vacinação contra brucelose e aftosa?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.ExamesDeBruceloseTuberculose != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Faz exames de brucelose, tuberculose e vacinação contra brucelose e aftosa?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.ExamesDeBruceloseTuberculoseRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Fazer exames de brucelose e tuberculose nos animais.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.ExamesDeBruceloseTuberculoseRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{ 'Colocar em dia a vacinação contra brucelose.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.ExamesDeBruceloseTuberculoseRecomendacao3"
              cols="12"
            >
              <span class="value-title">
                {{ 'Colocar em dia a vacinação contra aftosa.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.LoteVacaSecaSeparado == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'O lote vaca seca é separado dos demais animais?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.LoteVacaSecaSeparado != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'O lote vaca seca é separado dos demais animais?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col v-if="relatorio.LoteVacaSecaSeparadoRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Separar o lote de vacas secas dos demais animais.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ArmazenamentoAgrotoxicosEFertilizantes == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os agrotóxicos e fertilizantes são armazenados fora da área de produção e sala de alimentos?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.ArmazenamentoAgrotoxicosEFertilizantes != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os agrotóxicos e fertilizantes são armazenados fora da área de produção e sala de alimentos?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="
                relatorio.ArmazenamentoAgrotoxicosEFertilizantesRecomendacao1
              "
              cols="12"
            >
              <span class="value-title">
                {{
                  'Armazenar os agrotóxicos e fertilizantes fora da área de produção e sala de alimentos.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.TermMetroDigitalParaMonitoramento == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Tem termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.TermMetroDigitalParaMonitoramento != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Tem termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.TermMetroDigitalParaMonitoramentoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Providenciar termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.EquipamentosDeOrdenhaHigienizados == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os equipamentos de ordenha, tanque e utensílios são higienizados diariamente e estão regulados?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.EquipamentosDeOrdenhaHigienizados != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os equipamentos de ordenha, tanque e utensílios são higienizados diariamente e estão regulados?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.EquipamentosDeOrdenhaHigienizadosRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Higienizar diariamente os equipamentos de ordenha, tanque e utensílios.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.EquipamentosDeOrdenhaHigienizadosRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Contatar a assistência técnica especializada para regular o equipamento de ordenha.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="
            relatorio.DetergentesRegistradosComValidadeEIdentificaoCorreta ==
            'confirm'
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os detergentes alcalinos e ácidos são registrados pela anvisa? estão dentro da validade e com identificação correta?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="
            relatorio.DetergentesRegistradosComValidadeEIdentificaoCorreta != ''
          "
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os detergentes alcalinos e ácidos são registrados pela anvisa? estão dentro da validade e com identificação correta?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="
                relatorio.DetergentesRegistradosComValidadeEIdentificaoCorretaRecomendacao1
              "
              cols="12"
            >
              <span class="value-title">
                {{
                  'Utilizar somente detergentes alcalinos e ácidos registrados pela anvisa.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="
                relatorio.DetergentesRegistradosComValidadeEIdentificaoCorretaRecomendacao2
              "
              cols="12"
            >
              <span class="value-title">
                {{
                  'Utilizar somente produtos que estejam dentro do prazo de validade.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="
                relatorio.DetergentesRegistradosComValidadeEIdentificaoCorretaRecomendacao3
              "
              cols="12"
            >
              <span class="value-title">
                {{
                  'Identificar corretamente os produtos de acordo com sua utilização.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AlimentosComNotaFiscal == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Alimentos destinados aos animais tem nota fiscal para comprovar origem e são autorizados para ruminantes?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AlimentosComNotaFiscal != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Alimentos destinados aos animais tem nota fiscal para comprovar origem e são autorizados para ruminantes?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações:' }}
            </span>
            <v-col
              v-if="relatorio.AlimentosComNotaFiscalRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Armazenar nota fiscal para comprovar origem dos alimentos destinados aos animais.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.AlimentosComNotaFiscalRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{ 'Utilizar somente alimentos autorizados para ruminantes.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
        <span class="pa-3"></span>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ENV_APP } from '../../../../../env'
import { convertNumberBR } from '../../../../utils/masks'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
