<template>
  <v-card
    v-if="
      relatorio.RegistrosEmDia ||
      relatorio.ManterColaboradoresNaFazenda ||
      relatorio.FazendaLivreTrabalhoInfantil ||
      relatorio.ProducaoRespeitaOsLimitesLegais ||
      relatorio.FazendaRespeitaFolgasSemanais ||
      relatorio.UtilizacaoEpis ||
      relatorio.LocalAlojamentoAdequado ||
      relatorio.EquipamentosEmCondicoes ||
      relatorio.RecolhimentoInss ||
      relatorio.Recolhimentofgts ||
      relatorio.TempoDeIntervaloAdquado ||
      relatorio.TrabalhadoresAptos ||
      relatorio.TrabalhadoresTreinadosEpis
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Boas práticas sociais</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.RegistrosEmDia == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Todos os colaboradores estão com seus registros em dia e em conformidade com a legislação?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.RegistrosEmDia != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Todos os colaboradores estão com seus registros em dia e em conformidade com a legislação?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.RegistrosEmDiaRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Registrar todos os colaboradores conforme a legislação.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ManterColaboradoresNaFazenda == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'São mantidos na fazenda colaboradores com frequencia diária e contínua sem registro profissional?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.ManterColaboradoresNaFazenda != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'São mantidos na fazenda colaboradores com frequencia diária e contínua sem registro profissional?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.ManterColaboradoresNaFazendaRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Registrar todos os colaboradores conforme a legislação.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.FazendaLivreTrabalhoInfantil == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Fazenda livre de trabalho escravo e/ou infantil?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.FazendaLivreTrabalhoInfantil != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Fazenda livre de trabalho escravo e/ou infantil?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.FazendaLivreTrabalhoInfantilRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Registrar todos os colaboradores conforme a legislação.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.FazendaLivreTrabalhoInfantilRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Respeitar os limites legais de horas trabalhadas, considerando o máximo de horas extras.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.FazendaLivreTrabalhoInfantilRecomendacao3"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Respeitar a concessão de folgas semanais, considerando o mínimo exigido.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.FazendaLivreTrabalhoInfantilRecomendacao4"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Recolher o INSS conforme determina a lei, inclusive no pagamento de profissionais autônomos.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.FazendaLivreTrabalhoInfantilRecomendacao5"
              cols="12"
            >
              <span class="value-title">
                {{ 'Recolher o FGTS dentro dos prazos determinados por lei.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.FazendaLivreTrabalhoInfantilRecomendacao6"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Fornecer o devido intervalo de refeição e descanso para trabalhador garantindo o acesso a alimentação adequada.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.ProducaoRespeitaOsLimitesLegais == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A produção respeita os limites legais de horas trabalhadas, considerando o máximo de horas extras?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.ProducaoRespeitaOsLimitesLegais != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A produção respeita os limites legais de horas trabalhadas, considerando o máximo de horas extras?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.ProducaoRespeitaOsLimitesLegaisRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Respeitar os limites legais de horas trabalhadas, considerando o máximo de horas extras.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.FazendaRespeitaFolgasSemanais == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A fazenda respeita a concessão de folgas semanais, considerando o mínimo exigido?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.FazendaRespeitaFolgasSemanais != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A fazenda respeita a concessão de folgas semanais, considerando o mínimo exigido?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.FazendaRespeitaFolgasSemanaisRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Respeitar a concessão de folgas semanais, considerando o mínimo exigido.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.UtilizacaoEpis == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'São utilizados EPIs (equipamentos de proteção individual) determinados pela legislação para cada atividade?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.UtilizacaoEpis != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'São utilizados EPIs (equipamentos de proteção individual) determinados pela legislação para cada atividade?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.UtilizacaoEpisRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Utilizar EPIs (equipamentos de proteção individual) determinados pela legislação.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.LocalAlojamentoAdequado == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Quando trabalhadores residem na fazenda, os locais de alojamento são adequados para eles e seus familiares?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.LocalAlojamentoAdequado != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Quando trabalhadores residem na fazenda, os locais de alojamento são adequados para eles e seus familiares?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.LocalAlojamentoAdequadoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Melhorar e/ou reformar os locais de alojamento para os trabalhadores que residem na propriedade.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.EquipamentosEmCondicoes == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Equipamentos, máquinas e instalações em condições adequadas de uso e não oferecem risco aos trabalhadores?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.EquipamentosEmCondicoes != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Equipamentos, máquinas e instalações em condições adequadas de uso e não oferecem risco aos trabalhadores?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.EquipamentosEmCondicoesRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Realizar a troca de máquinas utilizadas no dia a dia.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.EquipamentosEmCondicoesRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar a troca de equipamentos utilizados no dia a dia.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.EquipamentosEmCondicoesRecomendacao3"
              cols="12"
            >
              <span class="value-title">
                {{ 'Realizar a reforma das instalações.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.RecolhimentoInss == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'O INSS está sendo recolhido conforme determina a lei, inclusive no pagamento de profissionais autônomos?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.RecolhimentoInss != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O INSS está sendo recolhido conforme determina a lei, inclusive no pagamento de profissionais autônomos?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.RecolhimentoInssRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Recolher o INSS conforme determina a lei, inclusive no pagamento de profissionais autônomos.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.Recolhimentofgts == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'O FGTS está sendo regularmente recolhido dentro dos prazos determinados por lei?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.Recolhimentofgts != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O FGTS está sendo regularmente recolhido dentro dos prazos determinados por lei?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.RecolhimentofgtsRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Recolher o FGTS dentro dos prazos determinados por lei.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.TempoDeIntervaloAdquado == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'É fornecido o devido intervalo de refeição e descanso para o trabalhador garantindo uma alimentação adequada?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.TempoDeIntervaloAdquado != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'É fornecido o devido intervalo de refeição e descanso para o trabalhador garantindo uma alimentação adequada?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.TempoDeIntervaloAdquadoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Fornecer o devido intervalo de refeição e descanso para trabalhador garantindo o acesso a alimentação adequada.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.TrabalhadoresAptos == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Trabalhadores são aptos para as atividades que exercem, ou recebem treinamento adequado antes de exerce-la?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.TrabalhadoresAptos != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Trabalhadores são aptos para as atividades que exercem, ou recebem treinamento adequado antes de exerce-la?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.TrabalhadoresAptosRecomendacao1" cols="12">
              <span class="value-title">
                {{
                  'Treinar os trabalhadores para que fiquem aptos a exercer atividades para ele determinadas.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.TrabalhadoresTreinadosEpis == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Trabalhadores que fazem uso de produtos químicos / agrotóxicos são treinados para tal e usam EPIs específicos?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.TrabalhadoresTreinadosEpis != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Trabalhadores que fazem uso de produtos químicos / agrotóxicos são treinados para tal e usam EPIs específicos?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.TrabalhadoresTreinadosEpisRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Utilizar EPIs (equipamentos de proteção individual) determinados pela legislação.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.TrabalhadoresTreinadosEpisRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Treinar os trabalhadores para que fiquem aptos a exercer atividades para ele determinadas.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
