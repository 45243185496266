<template>
  <v-card
    v-if="
      relatorio.TodosAnimaisSaoIdentificados ||
      relatorio.AdocaoSistemaDeMarcacao ||
      relatorio.RegistroEControleDeAnimais ||
      relatorio.LimpezaSalaDeOrdenhaDiariamente ||
      relatorio.LimpezaSalaDeTanqueDiariamente ||
      relatorio.SalaTanquePossuiHigiene ||
      relatorio.DepositoRacaoFechado ||
      relatorio.MedicamentosOrganizados ||
      relatorio.AnotacoesZootecnicas ||
      relatorio.EquipeRecebeTreinamento
    "
    class="ma-4"
  >
    <v-card-title style="text-align: left">Instalações e gestão</v-card-title>
    <v-card-text>
      <v-row class="pa-3">
        <v-col
          v-if="relatorio.TodosAnimaisSaoIdentificados == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'Todos os animais são identificados por brincos (vacas)?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.TodosAnimaisSaoIdentificados != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'Todos os animais são identificados por brincos (vacas)?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.TodosAnimaisSaoIdentificadosRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Identificar todos os animais por brincos (vacas).' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AdocaoSistemaDeMarcacao == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'É adotado um sistema de marcação por cor nas vacas?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AdocaoSistemaDeMarcacao != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'É adotado um sistema de marcação por cor nas vacas?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.AdocaoSistemaDeMarcacaoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Adotar um sistema de marcação por cor nas vacas.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.RegistroEControleDeAnimais == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'É realizado o registro e controle das vacas em tratamento, em fase colostral e vacas secas em agenda própria?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.RegistroEControleDeAnimais != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'É realizado o registro e controle das vacas em tratamento, em fase colostral e vacas secas em agenda própria?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.RegistroEControleDeAnimaisRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar o registro e controle das vacas em tratamento em agenda própria.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.RegistroEControleDeAnimaisRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar o registro e controle das vacas secas em agenda própria.'
                }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.RegistroEControleDeAnimaisRecomendacao3"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Realizar o registro e controle das vacas em fase colostral em agenda própria.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.LimpezaSalaDeOrdenhaDiariamente == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A sala de ordenha é limpa e organizada diariamente, com lâmpadas devidamente protegidas ou com lâmpada de LED?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.LimpezaSalaDeOrdenhaDiariamente != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A sala de ordenha é limpa e organizada diariamente, com lâmpadas devidamente protegidas ou com lâmpada de LED?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.LimpezaSalaDeOrdenhaDiariamenteRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Limpar e organizar diariamente a sala de ordenha.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.LimpezaSalaDeOrdenhaDiariamenteRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{ 'Proteger as lâmpadas ou troca-las por lâmpadas de LED.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.LimpezaSalaDeTanqueDiariamente == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A sala de tanque é limpa e organizada diariamente, com lâmpadas devidamente protegidas e sem acesso de pessoas não autorizadas?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.LimpezaSalaDeTanqueDiariamente != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A sala de tanque é limpa e organizada diariamente, com lâmpadas devidamente protegidas e sem acesso de pessoas não autorizadas?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.LimpezaSalaDeTanqueDiariamenteRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Proteger as lâmpadas ou troca-las por lâmpadas de LED.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.LimpezaSalaDeTanqueDiariamenteRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{ 'Limpar e organizar diariamente a sala do tanque.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.LimpezaSalaDeTanqueDiariamenteRecomendacao3"
              cols="12"
            >
              <span class="value-title">
                {{ 'Permitir acesso somente de pessoas autorizadas.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.SalaTanquePossuiHigiene == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'A sala de tanque possui torneira e detergente para higienização das mãos e utensílios?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.SalaTanquePossuiHigiene != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'A sala de tanque possui torneira e detergente para higienização das mãos e utensílios?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.SalaTanquePossuiHigieneRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Disponibilizar torneira e detergente para higienização das mãos e utensílios na sala do tanque.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.DepositoRacaoFechado == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'O depósito de ração é fechado, evitando entrada de outros animais, possui lampadas protegidas ou de LED?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.DepositoRacaoFechado != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'O depósito de ração é fechado, evitando entrada de outros animais, possui lampadas protegidas ou de LED?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.DepositoRacaoFechadoRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Proteger as lâmpadas ou troca-las por lâmpadas de LED.' }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.DepositoRacaoFechadoRecomendacao2" cols="12">
              <span class="value-title">
                {{
                  'Proteger o depósito de ração para evitar a entrada de pássaros e demais animais.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.MedicamentosOrganizados == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{
              'Os medicamentos veterinários estão organizados em local apropriado de acesso controlado?'
            }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.MedicamentosOrganizados != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{
              'Os medicamentos veterinários estão organizados em local apropriado de acesso controlado?'
            }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.MedicamentosOrganizadosRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{ 'Permitir acesso somente de pessoas autorizadas.' }}
              </span>
              <br />
            </v-col>
            <v-col
              v-if="relatorio.MedicamentosOrganizadosRecomendacao2"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Organizar em local apropriado os medicamentos veterinários.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.AnotacoesZootecnicas == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'São realizadas as anotações zootécnicas e controle leiteiro?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.AnotacoesZootecnicas != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'São realizadas as anotações zootécnicas e controle leiteiro?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col v-if="relatorio.AnotacoesZootecnicasRecomendacao1" cols="12">
              <span class="value-title">
                {{ 'Realizar as anotações zootécnicas.' }}
              </span>
              <br />
            </v-col>
            <v-col v-if="relatorio.AnotacoesZootecnicasRecomendacao2" cols="12">
              <span class="value-title">
                {{ 'Realizar controle leiteiro.' }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>

        <v-col
          v-if="relatorio.EquipeRecebeTreinamento == 'confirm'"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-check-outline"></span>
          <span class="value-title">
            {{ 'A equipe recebe treinamento e capacitação?' }}
          </span>
        </v-col>
        <v-col
          v-else-if="relatorio.EquipeRecebeTreinamento != ''"
          cols="12"
          class="col-bordered"
        >
          <span class="mdi mdi-close-outline"></span>
          <span class="value-title">
            {{ 'A equipe recebe treinamento e capacitação?' }}
          </span>
          <v-col cols="12" class="col-bordered">
            <span class="value-title">
              {{ 'Recomendações: ' }}
            </span>
            <v-col
              v-if="relatorio.EquipeRecebeTreinamentoRecomendacao1"
              cols="12"
            >
              <span class="value-title">
                {{
                  'Treinar os trabalhadores para que fiquem aptos a exercer atividades para ele determinadas.'
                }}
              </span>
              <br />
            </v-col>
          </v-col>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { convertNumberBR } from '../../../../utils/masks'
import { ENV_APP } from '../../../../../env'
export default {
  name: 'IndicadoresQualidade',
  props: {
    relatorio: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    convertNumberBR,
    getImagem(idImagem) {
      return `${ENV_APP.apiUri}/download-image/${idImagem}`
    },
  },
}
</script>

<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
