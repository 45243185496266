<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <div v-else>
      <v-card-title style="justify-content: center; font-size: 24px">
        <v-row justify="center">
          <v-col cols="12">
            <v-row justify="center"> Relatório boas práticas </v-row>
          </v-col>
          <v-col cols="12">
            <v-row justify="center">{{ relatorio.NumeroVisita }}ª visita</v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <InformacoesGeraisBP :relatorio="relatorio" />
      <IndicadoresQualidadeBP :relatorio="relatorio" />
      <QualidadeDoLeiteBP :relatorio="relatorio" />
      <ConfortoAnimalBP :relatorio="relatorio" />
      <SociaisBP :relatorio="relatorio" />
      <AmbientaisBP :relatorio="relatorio" />
      <InstalacoesGestaoBP :relatorio="relatorio" />
      <ProximaVisitaBP :relatorio="relatorio" />
      <v-col cols="12">
        <v-row justify="center">
          <v-btn color="primary" @click="exportPDF(relatorio)">
            <v-icon style="margin-right: 6px"> mdi-file-document </v-icon>
            <span style="font-weight: bold; font-size: 16px">Exportar PDF</span>
          </v-btn>
        </v-row>
      </v-col>
    </div>
  </v-card>
</template>

<script>
import exportPDF from '../../../../services/reports/RelatorioPDFBP'
import { exportReport } from '../../../../utils/report'
import AmbientaisBP from './AmbientaisBP'
import ConfortoAnimalBP from './ConfortoAnimalBP'
import IndicadoresQualidadeBP from './IndicadoresQualidadeBP'
import InformacoesGeraisBP from './InformacoesGeraisBP'
import InstalacoesGestaoBP from './InstalacoesGestaoBP'
import ProximaVisitaBP from './ProximaVisitaBP'
import QualidadeDoLeiteBP from './QualidadeDoLeiteBP'
import SociaisBP from './SociaisBP'

export default {
  components: {
    ConfortoAnimalBP,
    SociaisBP,
    InstalacoesGestaoBP,
    AmbientaisBP,
    IndicadoresQualidadeBP,
    InformacoesGeraisBP,
    QualidadeDoLeiteBP,
    ProximaVisitaBP,
  },

  props: {
    codRelatorio: {
      type: String,
      default: () => '',
    },
    draft: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: true,
      relatorio: null,
    }
  },
  mounted() {
    if (!this.codRelatorio) {
      this.$router.push({
        path: `/${this.$user.get().role}/relatorios/boaspraticas`,
      })
    } else {
      this.getRelatorio()
    }
  },
  methods: {
    exportPDF,
    getRelatorio() {
      this.api.get.relatorioccs(this.codRelatorio, this.draft).then(data => {
        this.relatorio = data
        this.loading = false
      })
    },
    async exportRel(relatorio) {
      if (!relatorio) relatorio = this.relatorio

      await exportReport({
        report: relatorio,
        exportReport: this.exportPDF,
      })
    },
    alert() {
      this.Swal.fire({
        title: 'Atenção',
        text: 'Em Desenvolvimento!!!',
        icon: 'warning',
      })
    },
  },
}
</script>
