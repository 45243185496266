import HtmlToCanvas from 'html2canvas'
import JsPDF from 'jspdf'
import moment from 'moment'
import { ENV_APP } from '../../../env'
import { convertNumberBR } from '../../utils/masks'

const utilsRelatorio = {
  getDataAtual() {
    var monName = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ]
    var now = new Date()

    var str =
      'Relatório gerado em ' +
      now.getDate() +
      ' de ' +
      monName[now.getMonth()] +
      ' de ' +
      now.getFullYear() +
      ' às ' +
      formatDate(now, 'HH:mm:ss') +
      '.'
    return str
  },
  calcLacVacas(relatorio) {
    if (relatorio.VacasLactacao && relatorio.VacasSecas) {
      return `${convertNumberBR(
        100 *
          (parseInt(relatorio.VacasLactacao) /
            (parseInt(relatorio.VacasLactacao) +
              parseInt(relatorio.VacasSecas))),
      )} %`
    } else {
      return '-'
    }
  },
  calcLacRebanho(relatorio) {
    var totalRebanho = 0
    if (relatorio.VacasLactacao) {
      totalRebanho += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalRebanho += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.Novilhas) {
      totalRebanho += parseInt(relatorio.Novilhas)
    }
    if (relatorio.Recria) {
      totalRebanho += parseInt(relatorio.Recria)
    }
    if (relatorio.Aleitamento) {
      totalRebanho += parseInt(relatorio.Aleitamento)
    }
    if (relatorio.Machos) {
      totalRebanho += parseInt(relatorio.Machos)
    }
    if (totalRebanho > 0) {
      return `${convertNumberBR(
        100 * (parseInt(relatorio.VacasLactacao) / totalRebanho),
      )} %`
    } else {
      return '-'
    }
  },
  calcProdutividade(relatorio) {
    if (relatorio.VacasLactacao && relatorio.ProducaoMedia) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / parseInt(relatorio.VacasLactacao),
      )} L / dia`
    } else {
      return '-'
    }
  },
  calcProdutividadeTotal(relatorio) {
    var totalVacas = 0
    if (relatorio.VacasLactacao) {
      totalVacas += parseInt(relatorio.VacasLactacao)
    }
    if (relatorio.VacasSecas) {
      totalVacas += parseInt(relatorio.VacasSecas)
    }
    if (relatorio.ProducaoMedia && totalVacas > 0) {
      return `${convertNumberBR(
        parseInt(relatorio.ProducaoMedia) / totalVacas,
      )} L / dia`
    } else {
      return '-'
    }
  },
}

function formatDate(date, format = 'DD/MM/YYYY') {
  return moment(date).format(format)
}

function renderCheckbox(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')

  doc.rect(startX, cursor + smallBoxHeight, tableLimit, line, 'F')

  return cursor + smallBoxHeight
}

function renderCheckboxList(doc, text, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    smallGutterY,
    startX,
    tableLimit,
  } = config

  doc.rect(startX, cursor, line, smallBoxHeight, 'F')

  doc.text(text, startX + smallGutterX, cursor + smallGutterY, {
    maxWidth: tableLimit - smallGutterX * 2,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight, 'F')
}

// eslint-disable-next-line no-unused-vars
function renderRecomendacoes(doc, text, data, cursor, config) {
  const {
    smallBoxHeight,
    line,
    smallGutterX,
    gutterY,
    startX,
    tableLimit,
  } = config

  if (!data) return cursor

  data = data || '-'
  text = text || '-'

  const width = doc.getTextWidth(data)
  const numberLines = parseInt(width / 250)

  cursor = checkAddPage(doc, cursor, smallBoxHeight * numberLines, config)

  doc.rect(startX, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor, tableLimit, line, 'F')

  doc.setFont('helvetica', 'normal')

  doc.text(data, startX + smallGutterX, cursor + gutterY - 5, {
    maxWidth: tableLimit - smallGutterX - 5,
  })

  doc.rect(startX + tableLimit, cursor, line, smallBoxHeight * numberLines, 'F')

  doc.rect(startX, cursor + smallBoxHeight * numberLines, tableLimit, line, 'F')
  return cursor + smallBoxHeight
}

function checkAddPage(doc, cursor, area, config) {
  const { heightLimit, margin, tableLimit, line, startX } = config

  if (cursor + area > heightLimit) {
    doc.addPage()
    cursor = margin
    doc.rect(startX, cursor, tableLimit, line, 'F')
  }

  return cursor
}

function header(doc, config, relatorio) {
  const logo1 = new Image()
  const logo2 = new Image()

  logo1.src = config.logo

  doc.addImage(logo1, 'PNG', config.margin, config.margin, 70, 55)

  if (relatorio.logoagroindustria) {
    logo2.src = relatorio.logoagroindustria
    doc.addImage(logo2, 'PNG', config.tableLimit - 50, config.margin, 73, 50)
  }

  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  const widthOfString = doc.getTextWidth('Relatório boas práticas')

  doc.text(
    'Relatório boas práticas',
    config.tableLimit / 2 - widthOfString / 2 + config.margin,
    config.margin + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')
}

function subHeader(doc, _ref, _ref2) {
  const {
    fazenda: {
      produtor: { Nome: produtor },
      pessoajuridica: { NomeFantasia: fazenda },
      producer: { Matricula: matricula },
    },
    consultor: {
      pessoafisica: { Nome: consultor },
    },
    cidadefazenda,
    DataAtendimento,
    HoraInicio,
    HoraFim,
    NumeroVisita,
  } = _ref
  const {
    boxHeight,
    gutterY,
    line,
    lineColor,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
    mediumFontSize,
    fontSize,
  } = _ref2

  doc.setFillColor(lineColor)

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Informações básicas', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text('Fazenda:', startX + smallGutterX, startY + smallGutterY + boxHeight)

  doc
    .setFont('helvetica', 'bold')
    .text(fazenda, startX + smallGutterX, startY + gutterY + boxHeight)
    .setFont('helvetica', 'normal')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produtor(a):',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    produtor.substring(0, 25),
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Matrícula:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    matricula ? matricula : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.text(
    'Consultor(a):',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(consultor, startX + smallGutterX, startY + boxHeight * 3 + gutterY)

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Cidade:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    cidadefazenda ? cidadefazenda.Nome : '-',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.text(
    'Data:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    DataAtendimento,
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do início:',
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraInicio,
    startX + tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Horário do término:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    HoraFim,
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 4,
    startY + boxHeight * 4,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número da visita:',
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    `${NumeroVisita}ª visita`,
    startX + tableLimit - tableLimit / 4 + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F')
}

function indicadoresQualidade(doc, relatorio, _ref2) {
  const {
    boxHeight,
    fontSize,
    line,
    gutterY,
    mediumFontSize,
    smallGutterX,
    smallGutterY,
    startX,
    startY,
    tableLimit,
  } = _ref2

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(mediumFontSize)
    .text('Principais Indicadores', startX, startY + 10)
    .setFont('helvetica', 'normal')
    .setFontSize(fontSize)

  doc.rect(startX, startY + boxHeight, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Produção média (l/dia):',
    startX + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProducaoMedia ? `${relatorio.ProducaoMedia}` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit / 3, startY + boxHeight, line, boxHeight, 'F')

  doc.text(
    'Proteína média:',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.ProteinaMediaUltimoMes + ' %' || 'Não informado',
    startX + tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(
    startX + tableLimit - tableLimit / 3,
    startY + boxHeight,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Gordura média:',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + smallGutterY,
  )

  doc.text(
    relatorio.GorduraMediaUltimoMes + ' %' || 'Não informado',
    startX + tableLimit - tableLimit / 3 + smallGutterX,
    startY + boxHeight + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 2, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.text(
    'CPP média:',
    startX + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.CppMedia ? `${relatorio.CppMedia} UFC/ml * 10³` : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 2,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'CCS média',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + smallGutterY,
  )

  doc.text(
    relatorio.CcsMedia
      ? `${relatorio.CcsMedia} céls/ml * 10³`
      : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 2 + gutterY,
  )

  doc.rect(startX + tableLimit, startY + boxHeight * 2, line, boxHeight, 'F')

  doc.rect(startX, startY + boxHeight * 3, tableLimit, line, 'F')

  doc.rect(startX, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.rect(startX + tableLimit, startY + boxHeight * 3, line, boxHeight, 'F')

  doc.text(
    'Número de vacas em lactação:',
    startX + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.NumDeVacasEmLactacao
      ? relatorio.NumDeVacasEmLactacao
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(
    startX + tableLimit / 2,
    startY + boxHeight * 3,
    line,
    boxHeight,
    'F',
  )

  doc.text(
    'Número de vacas secas:',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + smallGutterY,
  )

  doc.text(
    relatorio.NumDeVacasSecas ? relatorio.NumDeVacasSecas : 'Não informado',
    startX + tableLimit / 2 + smallGutterX,
    startY + boxHeight * 3 + gutterY,
  )

  doc.rect(startX, startY + boxHeight * 4, tableLimit, line, 'F') //superior

  doc.rect(startX, startY + boxHeight * 4, line, boxHeight, 'F') //esquerda

  doc.rect(startX + tableLimit, startY + boxHeight * 4, line, boxHeight, 'F') //direita

  doc.rect(startX, startY + boxHeight * 5, tableLimit, line, 'F') //inferior

  doc.text(
    'Número de pessoas envolvidas no manejo do rebanho:',
    startX + smallGutterX,
    startY + boxHeight * 4 + smallGutterY,
  )

  doc.text(
    relatorio.NumPessoasEnvolvidasNoManejoDoRebanho
      ? relatorio.NumPessoasEnvolvidasNoManejoDoRebanho
      : 'Não informado',
    startX + smallGutterX,
    startY + boxHeight * 4 + gutterY,
  )
}

function qualidadeDoLeite(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.OrdenhaSeparadaVacasDoentes ||
    relatorio.MediaGeometricaDentroDaLegislacao ||
    relatorio.OutrosAnimaisTemAcessoASalas ||
    relatorio.RealizacaoDaPreparaçãoDoAnimal ||
    relatorio.UtilizadaAguaQuenteNaLimpeza ||
    relatorio.ExamesDeBruceloseTuberculose ||
    relatorio.LoteVacaSecaSeparado ||
    relatorio.ArmazenamentoAgrotoxicosEFertilizantes ||
    relatorio.TermMetroDigitalParaMonitoramento ||
    relatorio.EquipamentosDeOrdenhaHigienizados ||
    relatorio.DetergentesRegistradosComValidadeEIdentificaoCorreta ||
    relatorio.AlimentosComNotaFiscal
  ) {
    cursor += config.boxHeight + 30

    if (cursor + boxHeight * 100 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Boas práticas em qualidade do leite', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    if (relatorio.OrdenhaSeparadaVacasDoentes == 'confirm') {
      renderCheckbox(
        doc,
        '(V) São ordenhadas separadamente e descartado o leite de vacas doentes, em tratamento e com colostro?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.OrdenhaSeparadaVacasDoentes != '') {
      renderCheckbox(
        doc,
        '(X) São ordenhadas separadamente e descartado o leite de vacas doentes, em tratamento e com colostro?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.OrdenhaSeparadaVacasDoentesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Separar e descartar o leite de vacas doentes, em tratamento e com colostro.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.MediaGeometricaDentroDaLegislacao == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A média geométrica dos últimos três meses de CPP e CCS estão dentro da legislação vigente?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.MediaGeometricaDentroDaLegislacao != '') {
      renderCheckbox(
        doc,
        '(X) A média geométrica dos últimos três meses de CPP e CCS estão dentro da legislação vigente?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Realizar a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Higienizar diariamente os equipamentos de ordenha, tanque e utensílios.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao4) {
        renderCheckboxList(
          doc,
          '  Contatar a assistência técnica especializada para regular o equipamento de ordenha.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.MediaGeometricaDentroDaLegislacaoRecomendacao5) {
        renderCheckboxList(
          doc,
          '  Utilizar água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.OutrosAnimaisTemAcessoASalas == 'confirm') {
      renderCheckbox(
        doc,
        '(V) As galinhas e suínos têm acesso à área de produção, sala do tanque e sala de ordenha?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.OutrosAnimaisTemAcessoASalas != '') {
      renderCheckbox(
        doc,
        '(X) As galinhas e suínos têm acesso à área de produção, sala do tanque e sala de ordenha?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.OutrosAnimaisTemAcessoASalasRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Proteger a área de produção, sala do tanque e sala de ordenha para que as galinhas e suínos tenham acesso.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.RealizacaoDaPreparaçãoDoAnimal == 'confirm') {
      renderCheckbox(
        doc,
        '(V) É realizado a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else {
      renderCheckbox(
        doc,
        '(X) É realizado a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RealizacaoDaPreparaçãoDoAnimalRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.UtilizadaAguaQuenteNaLimpeza == 'confirm') {
      renderCheckbox(
        doc,
        '(V) É utilizada água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.UtilizadaAguaQuenteNaLimpeza != '') {
      renderCheckbox(
        doc,
        '(X) É utilizada água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.UtilizadaAguaQuenteNaLimpezaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Utilizar água quente na limpeza dos equipamentos conforme recomendação do fabricante dos produtos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.ExamesDeBruceloseTuberculose == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Faz exames de brucelose, tuberculose e vacinação contra brucelose e aftosa?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.ExamesDeBruceloseTuberculose != '') {
      renderCheckbox(
        doc,
        '(X) Faz exames de brucelose, tuberculose e vacinação contra brucelose e aftosa?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.ExamesDeBruceloseTuberculoseRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Fazer exames de brucelose e tuberculose nos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.ExamesDeBruceloseTuberculoseRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Colocar em dia a vacinação contra brucelose.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.ExamesDeBruceloseTuberculoseRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Colocar em dia a vacinação contra aftosa.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.LoteVacaSecaSeparado == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O lote vaca seca é separado dos demais animais?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.LoteVacaSecaSeparado != '') {
      renderCheckbox(
        doc,
        '(X) O lote vaca seca é separado dos demais animais?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.LoteVacaSecaSeparadoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Separar o lote de vacas secas dos demais animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.ArmazenamentoAgrotoxicosEFertilizantes == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os agrotóxicos e fertilizantes são armazenados fora da área de produção e sala de alimentos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.ArmazenamentoAgrotoxicosEFertilizantes != '') {
      renderCheckbox(
        doc,
        '(X) Os agrotóxicos e fertilizantes são armazenados fora da área de produção e sala de alimentos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.ArmazenamentoAgrotoxicosEFertilizantesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Armazenar os agrotóxicos e fertilizantes fora da área de produção e sala de alimentos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.TermMetroDigitalParaMonitoramento == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Tem termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.TermMetroDigitalParaMonitoramento != '') {
      renderCheckbox(
        doc,
        '(X) Tem termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.TermMetroDigitalParaMonitoramentoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar termômetro digital para monitorar a temperatura da água quente e verificar a temperatura do leite.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (relatorio.EquipamentosDeOrdenhaHigienizados == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os equipamentos de ordenha, tanque e utensílios são higienizados diariamente e estão regulados?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.EquipamentosDeOrdenhaHigienizados != '') {
      renderCheckbox(
        doc,
        '(X) Os equipamentos de ordenha, tanque e utensílios são higienizados diariamente e estão regulados?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.EquipamentosDeOrdenhaHigienizadosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Higienizar diariamente os equipamentos de ordenha, tanque e utensílios.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.EquipamentosDeOrdenhaHigienizadosRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Contatar a assistência técnica especializada para regular o equipamento de ordenha.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    if (
      relatorio.DetergentesRegistradosComValidadeEIdentificaoCorreta ==
      'confirm'
    ) {
      renderCheckbox(
        doc,
        '(V) Os detergentes alcalinos e ácidos são registrados pela anvisa? estão dentro da validade e com identificação correta?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (
      relatorio.DetergentesRegistradosComValidadeEIdentificaoCorreta != ''
    ) {
      renderCheckbox(
        doc,
        '(X) Os detergentes alcalinos e ácidos são registrados pela anvisa? estão dentro da validade e com identificação correta?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (
        relatorio.DetergentesRegistradosComValidadeEIdentificaoCorretaRecomendacao1
      ) {
        renderCheckboxList(
          doc,
          '  Utilizar somente detergentes alcalinos e ácidos registrados pela anvisa.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (
        relatorio.DetergentesRegistradosComValidadeEIdentificaoCorretaRecomendacao2
      ) {
        renderCheckboxList(
          doc,
          '  Utilizar somente produtos que estejam dentro do prazo de validade.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (
        relatorio.DetergentesRegistradosComValidadeEIdentificaoCorretaRecomendacao3
      ) {
        renderCheckboxList(
          doc,
          '  Identificar corretamente os produtos de acordo com sua utilização.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AlimentosComNotaFiscal == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Alimentos destinados aos animais tem nota fiscal para comprovar origem e são autorizados para ruminantes?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AlimentosComNotaFiscal != '') {
      renderCheckbox(
        doc,
        '(X) Alimentos destinados aos animais tem nota fiscal para comprovar origem e são autorizados para ruminantes?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AlimentosComNotaFiscalRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Armazenar nota fiscal para comprovar origem dos alimentos destinados aos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.AlimentosComNotaFiscalRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Utilizar somente alimentos autorizados para ruminantes.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
  }
  cursor += smallBoxHeight

  return cursor
}

function confortoAnimal(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.PossuiProtocolosDeTratamentos ||
    relatorio.OrdenhaRealizadaDeFormaCorreta ||
    relatorio.AreaDePastejoEmBoasCondicoes ||
    relatorio.BebedourosAdequados ||
    relatorio.ControleDeCarrapatosEParasitas ||
    relatorio.PrevencaoEControleDeMastites ||
    relatorio.AnimaisConduzidosCorretamente ||
    relatorio.PastoEmBoasCondicoes ||
    relatorio.AlimentacaoSuficiente ||
    relatorio.CamasSuficientes ||
    relatorio.PermitirOBomDesenvolvimentoDosAnimais ||
    relatorio.OrdenhaMecanicaRegulada
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 6 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Boas práticas em conforto animal', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.PossuiProtocolosDeTratamentos == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A fazenda possui protocolos de tratamentos para as doenças mais frequentes do rebanho?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.PossuiProtocolosDeTratamentos != '') {
      renderCheckbox(
        doc,
        '(X) A fazenda possui protocolos de tratamentos para as doenças mais frequentes do rebanho?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.PossuiProtocolosDeTratamentosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar protocolos de tratamentos para as doenças mais frequentes do rebanho.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.OrdenhaRealizadaDeFormaCorreta == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A ordenha é realizada de forma calma, sem o uso de violência ou injúrias aos animais?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.OrdenhaRealizadaDeFormaCorreta != '') {
      renderCheckbox(
        doc,
        '(X) A ordenha é realizada de forma calma, sem o uso de violência ou injúrias aos animais?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.OrdenhaRealizadaDeFormaCorretaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar a ordenha de forma calma, sem o uso de violência ou injúrias aos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AreaDePastejoEmBoasCondicoes == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A área de pastejo dispõe de piquetes com sombra, livres do acúmulo de barro e esterco?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AreaDePastejoEmBoasCondicoes != '') {
      renderCheckbox(
        doc,
        '(X) A área de pastejo dispõe de piquetes com sombra, livres do acúmulo de barro e esterco?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AreaDePastejoEmBoasCondicoesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar sombrites na área de pastejo e descanso dos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.AreaDePastejoEmBoasCondicoesRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Limpar as áreas de pastejo de descanso dos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.BebedourosAdequados == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Bebedouros em quantidade suficiente e de fácil acesso?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.BebedourosAdequados != '') {
      renderCheckbox(
        doc,
        '(X) Bebedouros em quantidade suficiente e de fácil acesso?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.BebedourosAdequadosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Ajustar o número de animais para que todos tenham acesso ao bebedouro.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.BebedourosAdequadosRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Ajustar o número de bebedouro para que todos os animais tenham acesso.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.BebedourosAdequadosRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Melhorar o acesso dos animais aos bebedouros.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.ControleDeCarrapatosEParasitas == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os animais são submetidos a controle racional de carrapatos e parasitas diversos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.ControleDeCarrapatosEParasitas != '') {
      renderCheckbox(
        doc,
        '(X) Os animais são submetidos a controle racional de carrapatos e parasitas diversos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.ControleDeCarrapatosEParasitasRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar e executar controle estratégico para carrapatos e parasitas diversos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.PrevencaoEControleDeMastites == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Existe prevenção e controle de mastites clínicas?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.PrevencaoEControleDeMastites != '') {
      renderCheckbox(
        doc,
        '(X) Existe prevenção e controle de mastites clínicas?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.PrevencaoEControleDeMastitesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar a preparação do animal (teste da caneca, pré dipping, secagem e pós dipping) e higienização das mãos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AnimaisConduzidosCorretamente == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os animais são conduzidos para a ordenha de forma calma e ordenada?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AnimaisConduzidosCorretamente != '') {
      renderCheckbox(
        doc,
        '(X) Os animais são conduzidos para a ordenha de forma calma e ordenada?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AnimaisConduzidosCorretamenteRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Conduzir os animais para a ordenha de forma calma e ordenada.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.PastoEmBoasCondicoes == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Pasto de vacas secas, maternidade e pós-parto estão livres de acúmulo de barro?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.PastoEmBoasCondicoes != '') {
      renderCheckbox(
        doc,
        '(X) Pasto de vacas secas, maternidade e pós-parto estão livres de acúmulo de barro?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.PastoEmBoasCondicoesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Limpar as áreas de pastejo de descanso dos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AlimentacaoSuficiente == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os animais dispõem de alimentação volumosa suficiente a sua mantença o ano todo?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AlimentacaoSuficiente != '') {
      renderCheckbox(
        doc,
        '(X) Os animais dispõem de alimentação volumosa suficiente a sua mantença o ano todo?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AlimentacaoSuficienteRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar planejamento de volumoso para que os animais tenham alimento o ano todo.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.CamasSuficientes == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Dispõe do número de camas dimensionadas e que propiciem conforto?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.CamasSuficientes != '') {
      renderCheckbox(
        doc,
        '(X) Dispõe do número de camas dimensionadas e que propiciem conforto?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.CamasSuficientesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Ajustar a taxa de lotação dos lotes.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.PermitirOBomDesenvolvimentoDosAnimais == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O bezerreiro permite que animais se desenvolvam bem, livre de doenças e parasitas?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.PermitirOBomDesenvolvimentoDosAnimais != '') {
      renderCheckbox(
        doc,
        '(X) O bezerreiro permite que animais se desenvolvam bem, livre de doenças e parasitas?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.PermitirOBomDesenvolvimentoDosAnimaisRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar sombreamento aos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.PermitirOBomDesenvolvimentoDosAnimaisRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Higienizar diariamente o bezerreiro.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.PermitirOBomDesenvolvimentoDosAnimaisRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Higienizar diariamente os utensílios utilizados para a alimentação dos animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.OrdenhaMecanicaRegulada == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A ordenha mecânica está regulada, evitando desconforto e dor no ato da ordenha?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.OrdenhaMecanicaRegulada != '') {
      renderCheckbox(
        doc,
        '(X) A ordenha mecânica está regulada, evitando desconforto e dor no ato da ordenha?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.OrdenhaMecanicaReguladaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Contatar a assistência técnica especializada para regular o equipamento de ordenha.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }
    cursor += smallBoxHeight

    if (cursor + boxHeight * 11 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
  }
  return cursor
}

function sociais(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.RegistrosEmDia ||
    relatorio.ManterColaboradoresNaFazenda ||
    relatorio.FazendaLivreTrabalhoInfantil ||
    relatorio.ProducaoRespeitaOsLimitesLegais ||
    relatorio.FazendaRespeitaFolgasSemanais ||
    relatorio.UtilizacaoEpis ||
    relatorio.LocalAlojamentoAdequado ||
    relatorio.EquipamentosEmCondicoes ||
    relatorio.RecolhimentoInss ||
    relatorio.Recolhimentofgts ||
    relatorio.TempoDeIntervaloAdquado ||
    relatorio.TrabalhadoresAptos ||
    relatorio.TrabalhadoresTreinadosEpis
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 6 > heightLimit) {
      doc.addPage()
      cursor = margin + 10
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Boas práticas sociais', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.RegistrosEmDia == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Todos os colaboradores estão com seus registros em dia e em conformidade com a legislação?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.RegistrosEmDia != '') {
      renderCheckbox(
        doc,
        '(X) Todos os colaboradores estão com seus registros em dia e em conformidade com a legislação?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RegistrosEmDiaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Registrar todos os colaboradores conforme a legislação.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.ManterColaboradoresNaFazenda == 'confirm') {
      renderCheckbox(
        doc,
        '(V) São mantidos na fazenda colaboradores com frequencia diária e contínua sem registro profissional?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.ManterColaboradoresNaFazenda != '') {
      renderCheckbox(
        doc,
        '(X) São mantidos na fazenda colaboradores com frequencia diária e contínua sem registro profissional?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.ManterColaboradoresNaFazendaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Registrar todos os colaboradores conforme a legislação.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.FazendaLivreTrabalhoInfantil == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Fazenda livre de trabalho escravo e/ou infantil?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.FazendaLivreTrabalhoInfantil != '') {
      renderCheckbox(
        doc,
        '(X) Fazenda livre de trabalho escravo e/ou infantil?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.FazendaLivreTrabalhoInfantilRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Registrar todos os colaboradores conforme a legislação.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.FazendaLivreTrabalhoInfantilRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Respeitar os limites legais de horas trabalhadas, considerando o máximo de horas extras.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.FazendaLivreTrabalhoInfantilRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Respeitar a concessão de folgas semanais, considerando o mínimo exigido.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.FazendaLivreTrabalhoInfantilRecomendacao4) {
        renderCheckboxList(
          doc,
          '  Recolher o INSS conforme determina a lei, inclusive no pagamento de profissionais autônomos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.FazendaLivreTrabalhoInfantilRecomendacao5) {
        renderCheckboxList(
          doc,
          '  Recolher o FGTS dentro dos prazos determinados por lei.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      if (relatorio.FazendaLivreTrabalhoInfantilRecomendacao6) {
        renderCheckboxList(
          doc,
          '  Fornecer o devido intervalo de refeição e descanso para trabalhador garantindo o acesso a alimentação adequada.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.ProducaoRespeitaOsLimitesLegais == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A produção respeita os limites legais de horas trabalhadas, considerando o máximo de horas extras?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.ProducaoRespeitaOsLimitesLegais != '') {
      renderCheckbox(
        doc,
        '(X) A produção respeita os limites legais de horas trabalhadas, considerando o máximo de horas extras?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.ProducaoRespeitaOsLimitesLegaisRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Respeitar os limites legais de horas trabalhadas, considerando o máximo de horas extras.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.FazendaRespeitaFolgasSemanais == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A fazenda respeita a concessão de folgas semanais, considerando o mínimo exigido?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.FazendaRespeitaFolgasSemanais != '') {
      renderCheckbox(
        doc,
        '(X) A fazenda respeita a concessão de folgas semanais, considerando o mínimo exigido?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.FazendaRespeitaFolgasSemanaisRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Respeitar a concessão de folgas semanais, considerando o mínimo exigido.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.UtilizacaoEpis == 'confirm') {
      renderCheckbox(
        doc,
        '(V) São utilizados EPIs (equipamentos de proteção individual) determinados pela legislação para cada atividade?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.UtilizacaoEpis != '') {
      renderCheckbox(
        doc,
        '(X) São utilizados EPIs (equipamentos de proteção individual) determinados pela legislação para cada atividade?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.UtilizacaoEpisRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Utilizar EPIs (equipamentos de proteção individual) determinados pela legislação.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.LocalAlojamentoAdequado == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Quando trabalhadores residem na fazenda, os locais de alojamento são adequados para eles e seus familiares?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.LocalAlojamentoAdequado != '') {
      renderCheckbox(
        doc,
        '(X) Quando trabalhadores residem na fazenda, os locais de alojamento são adequados para eles e seus familiares?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.LocalAlojamentoAdequadoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Melhorar e/ou reformar os locais de alojamento para os trabalhadores que residem na propriedade.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.EquipamentosEmCondicoes == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Equipamentos, máquinas e instalações em condições adequadas de uso e não oferecem risco aos trabalhadores?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.EquipamentosEmCondicoes != '') {
      renderCheckbox(
        doc,
        '(X) Equipamentos, máquinas e instalações em condições adequadas de uso e não oferecem risco aos trabalhadores?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.EquipamentosEmCondicoesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar a troca de máquinas utilizadas no dia a dia.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.EquipamentosEmCondicoesRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Realizar a troca de equipamentos utilizados no dia a dia.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.EquipamentosEmCondicoesRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Realizar a reforma das instalações.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.RecolhimentoInss == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O INSS está sendo recolhido conforme determina a lei, inclusive no pagamento de profissionais autônomos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.RecolhimentoInss != '') {
      renderCheckbox(
        doc,
        '(X) O INSS está sendo recolhido conforme determina a lei, inclusive no pagamento de profissionais autônomos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RecolhimentoInssRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Recolher o INSS conforme determina a lei, inclusive no pagamento de profissionais autônomos.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.Recolhimentofgts == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O FGTS está sendo regularmente recolhido dentro dos prazos determinados por lei?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.Recolhimentofgts != '') {
      renderCheckbox(
        doc,
        '(X) O FGTS está sendo regularmente recolhido dentro dos prazos determinados por lei?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RecolhimentofgtsRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Recolher o FGTS dentro dos prazos determinados por lei.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.TempoDeIntervaloAdquado == 'confirm') {
      renderCheckbox(
        doc,
        '(V) É fornecido o devido intervalo de refeição e descanso para o trabalhador garantindo uma alimentação adequada?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.TempoDeIntervaloAdquado != '') {
      renderCheckbox(
        doc,
        '(X) É fornecido o devido intervalo de refeição e descanso para o trabalhador garantindo uma alimentação adequada?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.TempoDeIntervaloAdquadoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Fornecer o devido intervalo de refeição e descanso para trabalhador garantindo o acesso a alimentação adequada.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.TrabalhadoresAptos == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Trabalhadores são aptos para as atividades que exercem, ou recebem treinamento adequado antes de exerce-la?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.TrabalhadoresAptos != '') {
      renderCheckbox(
        doc,
        '(X) Trabalhadores são aptos para as atividades que exercem, ou recebem treinamento adequado antes de exerce-la?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.TrabalhadoresAptosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Treinar os trabalhadores para que fiquem aptos a exercer atividades para ele determinadas.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.TrabalhadoresTreinadosEpis == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Trabalhadores que fazem uso de produtos químicos / agrotóxicos são treinados para tal e usam EPIs específicos?',
        cursor,
        config,
      )

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.TrabalhadoresTreinadosEpis != '') {
      renderCheckbox(
        doc,
        '(X) Trabalhadores que fazem uso de produtos químicos / agrotóxicos são treinados para tal e usam EPIs específicos?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.TrabalhadoresTreinadosEpisRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Utilizar EPIs (equipamentos de proteção individual) determinados pela legislação.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.TrabalhadoresTreinadosEpisRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Treinar os trabalhadores para que fiquem aptos a exercer atividades para ele determinadas.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor += smallBoxHeight

    if (cursor + boxHeight * 11 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
  }

  return cursor
}

function ambientais(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.PropriedadeRuralEmDia ||
    relatorio.EstruturaLimpa ||
    relatorio.DestinacaoDejetos ||
    relatorio.DesmatamentoSemAutorizacao ||
    relatorio.DescarteLubrificantes ||
    relatorio.DestinacaoEsgotoDomestico ||
    relatorio.PreparoDaTerraEvitandoErosao ||
    relatorio.AreaPlantioRespeitaLimites ||
    relatorio.AtividadesPredatoriasNaPropriedade
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 6 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Boas práticas ambientais', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.PropriedadeRuralEmDia == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade rural está em dia com o CAR?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.PropriedadeRuralEmDia != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade rural está em dia com o CAR?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.PropriedadeRuralEmDiaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar o CAR (cadastro ambiental rural).',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.EstruturaLimpa == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A estrutura de curral de manejo e ordenha é limpa diariamente não permitindo o acúmulo de esterco?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.EstruturaLimpa != '') {
      renderCheckbox(
        doc,
        '(X) A estrutura de curral de manejo e ordenha é limpa diariamente não permitindo o acúmulo de esterco?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.EstruturaLimpaRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Limpar diariamente a estrutura de ordenha.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.EstruturaLimpaRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Limpar diariamente a estrutura de curral.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.DestinacaoDejetos == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os dejetos são destinados frescos para rios, córregos, lagos ou outras fontes de água?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.DestinacaoDejetos != '') {
      renderCheckbox(
        doc,
        '(X) Os dejetos são destinados frescos para rios, córregos, lagos ou outras fontes de água?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.DestinacaoDejetosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar o destino correto para os dejetos. (ex: caixa de dejetos)',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.DesmatamentoSemAutorizacao == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A propriedade utiliza  de  desmatamento   de   mata   nativa, sem autorização para qualquer fim?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.DesmatamentoSemAutorizacao != '') {
      renderCheckbox(
        doc,
        '(X) A propriedade utiliza  de  desmatamento   de   mata   nativa, sem autorização para qualquer fim?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.DesmatamentoSemAutorizacaoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Não realizar desmatamento de mata nativa sem autorização.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.DescarteLubrificantes == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Lubrificantes são descartados da forma adequada?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.DescarteLubrificantes != '') {
      renderCheckbox(
        doc,
        '(X) Lubrificantes são descartados da forma adequada?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.DescarteLubrificantesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Descartar lubrificantes na forma adequada (armazenar em tambores e levar em local próprio para descarte).',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.DestinacaoEsgotoDomestico == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Casas nas dependências da propriedade tem destino adequado para o esgoto doméstico?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.DestinacaoEsgotoDomestico != '') {
      renderCheckbox(
        doc,
        '(X) Casas nas dependências da propriedade tem destino adequado para o esgoto doméstico?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.DestinacaoEsgotoDomesticoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Providenciar destino adequado para o esgoto doméstico (ex: fossas sépticas).',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.PreparoDaTerraEvitandoErosao == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Preparo da terra para plantio em áreas íngremes respeitam curvas de nível ou preservam estrutura evitando erosão?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.PreparoDaTerraEvitandoErosao != '') {
      renderCheckbox(
        doc,
        '(X) Preparo da terra para plantio em áreas íngremes respeitam curvas de nível ou preservam estrutura evitando erosão?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.PreparoDaTerraEvitandoErosaoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Respeitar áreas íngremes e curvas de nível no preparo da terra para plantio.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AreaPlantioRespeitaLimites == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Áreas de plantio ou pastagem respeitam o limite mínimo de mata proximo dos rios ou mananciais?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AreaPlantioRespeitaLimites != '') {
      renderCheckbox(
        doc,
        '(X) Áreas de plantio ou pastagem respeitam o limite mínimo de mata proximo dos rios ou mananciais?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AreaPlantioRespeitaLimitesRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Respeitar o limite mínimo de mata proximo dos rios ou mananciais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AtividadesPredatoriasNaPropriedade == 'confirm') {
      renderCheckbox(
        doc,
        '(V) São realizadas atividades de caça e pesca, mesmo que por terceiros, nas dependências da propriedade?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AtividadesPredatoriasNaPropriedade != '') {
      renderCheckbox(
        doc,
        '(X) São realizadas atividades de caça e pesca, mesmo que por terceiros, nas dependências da propriedade?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AtividadesPredatoriasNaPropriedadeRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Não permitir atividades de caça e pesca, mesmo que por terceiros, nas dependências da propriedade.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor += smallBoxHeight

    if (cursor + boxHeight * 11 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
  }
  return cursor
}

function instalacaoEGestao(doc, relatorio, config, cursor) {
  const {
    boxHeight,
    fontSize,
    mediumFontSize,
    startX,
    smallBoxHeight,
    heightLimit,
    margin,
    tableLimit,
    line,
  } = config

  if (
    relatorio.TodosAnimaisSaoIdentificados ||
    relatorio.AdocaoSistemaDeMarcacao ||
    relatorio.RegistroEControleDeAnimais ||
    relatorio.LimpezaSalaDeOrdenhaDiariamente ||
    relatorio.LimpezaSalaDeTanqueDiariamente ||
    relatorio.SalaTanquePossuiHigiene ||
    relatorio.DepositoRacaoFechado ||
    relatorio.MedicamentosOrganizados ||
    relatorio.AnotacoesZootecnicas ||
    relatorio.EquipeRecebeTreinamento
  ) {
    cursor += config.boxHeight

    if (cursor + boxHeight * 6 > heightLimit) {
      doc.addPage()
      cursor = margin
    }

    doc
      .setFont('helvetica', 'bold')
      .setFontSize(mediumFontSize)
      .text('Instalações e gestão', startX, cursor)
      .setFont('helvetica', 'normal')
      .setFontSize(fontSize)

    cursor += smallBoxHeight

    cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

    if (relatorio.TodosAnimaisSaoIdentificados == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Todos os animais são identificados por brincos (vacas)?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.TodosAnimaisSaoIdentificados != '') {
      renderCheckbox(
        doc,
        '(X) Todos os animais são identificados por brincos (vacas)?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.TodosAnimaisSaoIdentificadosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Identificar todos os animais por brincos (vacas).',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AdocaoSistemaDeMarcacao == 'confirm') {
      renderCheckbox(
        doc,
        '(V) É adotado um sistema de marcação por cor nas vacas?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AdocaoSistemaDeMarcacao != '') {
      renderCheckbox(
        doc,
        '(X) É adotado um sistema de marcação por cor nas vacas?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AdocaoSistemaDeMarcacaoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Adotar um sistema de marcação por cor nas vacas.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.RegistroEControleDeAnimais == 'confirm') {
      renderCheckbox(
        doc,
        '(V) É realizado o registro e controle das vacas em tratamento, em fase colostral e vacas secas em agenda própria?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.RegistroEControleDeAnimais != '') {
      renderCheckbox(
        doc,
        '(X) É realizado o registro e controle das vacas em tratamento, em fase colostral e vacas secas em agenda própria?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.RegistroEControleDeAnimaisRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar o registro e controle das vacas em tratamento em agenda própria.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.RegistroEControleDeAnimaisRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Realizar o registro e controle das vacas secas em agenda própria.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.RegistroEControleDeAnimaisRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Realizar o registro e controle das vacas em fase colostral em agenda própria.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.LimpezaSalaDeOrdenhaDiariamente == 'confirm') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(V) A sala de ordenha é limpa e organizada diariamente, com lâmpadas devidamente protegidas ou com lâmpada de LED?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.LimpezaSalaDeOrdenhaDiariamente != '') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(X) A sala de ordenha é limpa e organizada diariamente, com lâmpadas devidamente protegidas ou com lâmpada de LED?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.LimpezaSalaDeOrdenhaDiariamenteRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Limpar e organizar diariamente a sala de ordenha.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.LimpezaSalaDeOrdenhaDiariamenteRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Proteger as lâmpadas ou troca-las por lâmpadas de LED.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.LimpezaSalaDeTanqueDiariamente == 'confirm') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(V) A sala de tanque é limpa e organizada diariamente, com lâmpadas devidamente protegidas e sem acesso de pessoas não autorizadas?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.LimpezaSalaDeTanqueDiariamente != '') {
      cursor = renderRecomendacoes(
        doc,
        '',
        '(X) A sala de tanque é limpa e organizada diariamente, com lâmpadas devidamente protegidas e sem acesso de pessoas não autorizadas?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.LimpezaSalaDeTanqueDiariamenteRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Proteger as lâmpadas ou troca-las por lâmpadas de LED.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.LimpezaSalaDeTanqueDiariamenteRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Limpar e organizar diariamente a sala do tanque.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.LimpezaSalaDeTanqueDiariamenteRecomendacao3) {
        renderCheckboxList(
          doc,
          '  Permitir acesso somente de pessoas autorizadas.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.SalaTanquePossuiHigiene == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A sala de tanque possui torneira e detergente para higienização das mãos e utensílios?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.SalaTanquePossuiHigiene != '') {
      renderCheckbox(
        doc,
        '(X) A sala de tanque possui torneira e detergente para higienização das mãos e utensílios?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.SalaTanquePossuiHigieneRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Disponibilizar torneira e detergente para higienização das mãos e utensílios na sala do tanque.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.DepositoRacaoFechado == 'confirm') {
      renderCheckbox(
        doc,
        '(V) O depósito de ração é fechado, evitando entrada de outros animais, possui lampadas protegidas ou de LED?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.DepositoRacaoFechado != '') {
      renderCheckbox(
        doc,
        '(X) O depósito de ração é fechado, evitando entrada de outros animais, possui lampadas protegidas ou de LED?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.DepositoRacaoFechadoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Proteger as lâmpadas ou troca-las por lâmpadas de LED.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.DepositoRacaoFechadoRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Proteger o depósito de ração para evitar a entrada de pássaros e demais animais.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.MedicamentosOrganizados == 'confirm') {
      renderCheckbox(
        doc,
        '(V) Os medicamentos veterinários estão organizados em local apropriado de acesso controlado?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.MedicamentosOrganizados != '') {
      renderCheckbox(
        doc,
        '(X) Os medicamentos veterinários estão organizados em local apropriado de acesso controlado?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.MedicamentosOrganizadosRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Permitir acesso somente de pessoas autorizadas.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.MedicamentosOrganizadosRecomendacao2) {
        renderCheckboxList(
          doc,
          '  Organizar em local apropriado os medicamentos veterinários.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor = checkAddPage(doc, cursor, smallBoxHeight * 4, config)

    if (relatorio.AnotacoesZootecnicas == 'confirm') {
      renderCheckbox(
        doc,
        '(V) São realizadas as anotações zootécnicas e controle leiteiro?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.AnotacoesZootecnicas != '') {
      renderCheckbox(
        doc,
        '(X) São realizadas as anotações zootécnicas e controle leiteiro?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.AnotacoesZootecnicasRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Realizar as anotações zootécnicas.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      if (relatorio.AnotacoesZootecnicasRecomendacao2) {
        renderCheckboxList(doc, '  Realizar controle leiteiro.', cursor, config)
        cursor += smallBoxHeight
        doc.rect(startX, cursor, tableLimit, line, 'F')
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }
    }

    if (relatorio.EquipeRecebeTreinamento == 'confirm') {
      renderCheckbox(
        doc,
        '(V) A equipe recebe treinamento e capacitação?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
    } else if (relatorio.EquipeRecebeTreinamento != '') {
      renderCheckbox(
        doc,
        '(X) A equipe recebe treinamento e capacitação?',
        cursor,
        config,
      )
      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)

      renderCheckboxList(doc, 'Recomendações:', cursor, config)

      cursor += smallBoxHeight
      cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      if (relatorio.EquipeRecebeTreinamentoRecomendacao1) {
        renderCheckboxList(
          doc,
          '  Treinar os trabalhadores para que fiquem aptos a exercer atividades para ele determinadas.',
          cursor,
          config,
        )
        cursor += smallBoxHeight
        cursor = checkAddPage(doc, cursor, smallBoxHeight, config)
      }

      doc.rect(startX, cursor, tableLimit, line, 'F')
    }

    cursor += smallBoxHeight

    if (cursor + boxHeight * 11 > heightLimit) {
      doc.addPage()
      cursor = margin
    }
  }
  return cursor
}

function proximaVisitaAssinaturas(doc, relatorio, config, cursor) {
  const {
    smallBoxHeight,
    boxHeight,
    tableLimit,
    startX,
    smallGutterY,
    line,
    heightLimit,
    margin,
  } = config

  cursor += config.boxHeight + 30

  if (cursor + boxHeight * 10 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  const proximaVisita = relatorio.DataProximaVisita
    ? relatorio.DataProximaVisita
    : '-'

  doc.text('Data da próxima visita', tableLimit / 2 - 30, cursor + boxHeight)
  doc.text(
    proximaVisita,
    tableLimit / 2 - 5,
    cursor + boxHeight + smallBoxHeight,
  )

  cursor += boxHeight * 2

  const produtor = new Image()

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    produtor.src = relatorio.AssinaturaProdutor
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  } else {
    produtor.src = relatorio.AssinaturaResponsavel
    doc.addImage(
      produtor,
      'PNG',
      startX + 50,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  const consultor = new Image()
  consultor.src = relatorio.AssinaturaConsultor

  if (relatorio.AssinaturaConsultor) {
    doc.addImage(
      consultor,
      'PNG',
      tableLimit - 180,
      cursor - 140,
      100,
      145,
      '',
      'FAST',
      270,
    )
  }

  doc.rect(startX, cursor + 100, tableLimit / 2.1, line, 'F')

  if (relatorio.AssinaturaProdutorOuResponsavel === '1') {
    doc.text(
      relatorio.fazenda.produtor.Nome,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura produtor(a)',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  } else {
    doc.text(
      relatorio.NomeResponsavel,
      tableLimit / 3.5,
      cursor + 100 + smallGutterY,
      null,
      null,
      'center',
    )
    doc.text(
      'Assinatura do responsável',
      tableLimit / 3.5,
      cursor + 115 + smallGutterY,
      null,
      null,
      'center',
    )
  }

  doc.rect(startX + 280, cursor + 100, tableLimit / 2.1, line, 'F')
  doc.text(
    relatorio.consultor.pessoafisica.Nome,
    startX + 420,
    cursor + 100 + smallGutterY,
    null,
    null,
    'center',
  )
  doc.text(
    'Assinatura consultor(a)',
    startX + 420,
    cursor + 115 + smallGutterY,
    null,
    null,
    'center',
  )

  cursor += boxHeight * 2

  return cursor
}

function rodapeImpressao(doc, config, cursor, text) {
  const { boxHeight, heightLimit, margin } = config
  const docWidth = doc.internal.pageSize.width
  const texto =
    utilsRelatorio.getDataAtual() +
    '       ' +
    `${ENV_APP.relUri}` +
    '        Pag. ' +
    text

  if (cursor + boxHeight * 2 > heightLimit) {
    doc.addPage()
    cursor = margin
  }

  doc.text(texto, docWidth / 2, heightLimit + 7, 'center')
}

export default data => {
  return new Promise(function (resolve, reject) {
    const MARGIN = 25
    const realStartY = 95
    var doc = new JsPDF({
      format: 'a4',
      compress: true,
      unit: 'pt',
    })
    var config = {
      startY: realStartY,
      startX: MARGIN,
      smallGutterY: 12,
      smallGutterX: 6,
      line: 0.3,
      tableLimit: doc.internal.pageSize.width - MARGIN - MARGIN,
      heightLimit: doc.internal.pageSize.height - MARGIN,
      margin: MARGIN,
      lineColor: '#000',
      boxHeight: 27,
      smallBoxHeight: 18,
      gutterX: 14,
      gutterY: 23,
      smallFontSize: 6.5,
      fontSize: 10,
      largefontSize: 16,
      mediumFontSize: 12,
      logo: 'img/' + `${ENV_APP.imgFileLogin}`,
      bodyStarY: realStartY + 25 * 8.5,
    }

    try {
      if (document.querySelector('#chart-relevancia')) {
        HtmlToCanvas(document.querySelector('#chart-relevancia')).then(
          canvas => {
            var dataURL = canvas.toDataURL()

            doc.addImage(
              dataURL,
              'PNG',
              config.startX - 5,
              config.startY + config.boxHeight * 9 + 33,
              556,
              120,
            )
            var cursor = 0
            header(doc, config, data)
            subHeader(doc, data, config)
            config.startY = config.startY + config.boxHeight * 4 + 40
            indicadoresQualidade(doc, data, config)
            config.startY = cursor + config.boxHeight + 10
            cursor = qualidadeDoLeite(doc, data, config, cursor)
            config.startY = cursor + config.boxHeight - 3
            cursor = confortoAnimal(doc, data, config, cursor)
            config.startY = cursor + config.boxHeight - 3
            cursor = sociais(doc, data, config, cursor)
            config.startY = cursor + config.boxHeight - 3
            cursor = ambientais(doc, data, config, cursor)
            config.startY = cursor + config.boxHeight - 3
            cursor = instalacaoEGestao(doc, data, config, cursor)
            config.startY = cursor + config.boxHeight - 3
            cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
            const pageCount = doc.internal.getNumberOfPages()

            for (var i = 1; i <= pageCount; i++) {
              doc.setPage(i)
              rodapeImpressao(
                doc,
                config,
                cursor + config.boxHeight,
                String(i) + ' de ' + String(pageCount),
              )
            }
            doc
              .save(
                `relatorio-bpa-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
                { returnPromise: true },
              )
              .then(() => {
                return resolve()
              })
          },
        )
      } else {
        var cursor = 0
        header(doc, config, data)
        subHeader(doc, data, config)
        config.startY = config.startY + config.boxHeight * 4 + 40
        indicadoresQualidade(doc, data, config)
        config.startY = cursor + config.boxHeight + 10
        cursor = qualidadeDoLeite(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = confortoAnimal(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = sociais(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = ambientais(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = instalacaoEGestao(doc, data, config, cursor)
        config.startY = cursor + config.boxHeight - 3
        cursor = proximaVisitaAssinaturas(doc, data, config, cursor)
        const pageCount = doc.internal.getNumberOfPages()

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i)
          rodapeImpressao(
            doc,
            config,
            cursor + config.boxHeight,
            String(i) + ' de ' + String(pageCount),
          )
        }
        doc
          .save(
            `relatorio-bpa-${data.NumeroVisita}-${data.fazenda.pessoajuridica.NomeFantasia}.pdf`,
            { returnPromise: true },
          )
          .then(() => {
            return resolve()
          })
      }
    } catch (err) {
      reject(err)
    }
  })
}
